<template>
    <div v-if="params" class="attribute-option-row">
        <div class="row">
            <div class="col-12">
                <mercur-checkbox v-model="key" :value="params.data.attributeName" @change="checkKey"><strong>{{ params.data.attributeName | underlineToSpace }}</strong></mercur-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col-4" v-for="(option, index) in params.data.attributeOptions" :key="index">
                <mercur-checkbox v-model="selectedItems" :value="params.data.attributeOptions[index]" @change="checkItems">{{ option | underlineToSpace }}</mercur-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
    name: 'AttributeOptionRow',
    data () {
        return {
            key: '',
            selectedItems: [],
        }
    },
    watch: {
        preSelectedOptions: {
            handler (value) {
                if (!Object.keys(value).length) {
                    this.key = ''
                    this.selectedItems = []
                    return
                }

                if (Array.isArray(value) && !value.length) {
                    this.key = ''
                    this.selectedItems = []
                    return
                }

                if (value[this.params.data.attributeName] && !value[this.params.data.attributeName].length) {
                    this.key = this.params.data.attributeName
                }

                if (value[this.params.data.attributeName] && value[this.params.data.attributeName].length) {
                    this.selectedItems = value[this.params.data.attributeName]
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        preSelectedOptions: {
            get () {
                return this.params.getValue()
            },
        },
    },
    methods: {
        checkItems () {
            if (this.selectedItems.length) {
                this.key = ''
            }
            this.params.setOptions(this.params.data.attributeName, this.selectedItems)
        },
        checkKey () {
            if (this.key.length) {
                this.selectedItems = []
            }
            this.params.setOptions(this.key, [])
        },
    },
})
</script>

<style lang="scss" scoped>
    .attribute-option-row {
        padding: 0 15px;
    }
</style>
