<template>
    <div class="c-product-view">
        <v-title title="Attributes"></v-title>
        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Attributes Labeling</h1>
                </div>
            </div>
        </title-box>
        <div class="container-fluid mt-3" v-if="selectedShop.applicationId !== '0'">
            <template v-if="hasPermission('MerchantCentral/getProductAttributes')">
                <div class="row">
                    <div class="col-8">
                        <shop-agreements @changed="getProductDefinitions"></shop-agreements>
                    </div>
                </div>
                <div class="row">
                    <div class="col-8">
                        <div v-show="shopAgreements && url">
                            <template v-if="url">
                                <merchant-data-table :options="options" class="shadow" :url="url" ref="table" :isNotApplicationBound="true"></merchant-data-table>
                            </template>
                        </div>
                    </div>
                    <div class="col-4">
                        <mercur-card v-if="agreement">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1">
                                    <h3 class="font-weight-normal">Attribute labels</h3>
                                </div>
                                <div>
                                    <add-label message="Attribute/options label was added" :action="action" @success="appendLabel"></add-label>

                                </div>
                            </div>
                            <label-listing @edit="editProductLabel" @update="updateLabel" @delete="deleteLabel" ref="listing" v-if="items.length" :items="items"></label-listing>
                        </mercur-card>
                    </div>
                </div>
            </template>
            <template v-else>
                Not allowed to see this view
            </template>
        </div>
        <div class="container-fluid" v-else>
            <mercur-card class="mt-n4">
                <p>Select shop to see attributes</p>
            </mercur-card>
        </div>
    </div>
</template>

<script>
import ShopAgreements from '@/components/elements/products/ShopAgreements'
import CONFIG from '@root/config'
import MerchantDataTable from '@/components/elements/MerchantDataTable'

import { mapState } from 'vuex'
import AttributeOptionRow from '@/components/elements/products/AttributeOptionRow'
import AddLabel from '@/components/elements/products/AddLabel'
import LabelListing from '@/components/elements/products/LabelListing'

export default {
    name: 'AttributesView',
    components: { ShopAgreements, MerchantDataTable, AddLabel, LabelListing },
    data () {
        return {
            url: null,
            getRowHeight: null,
            activeItem: null,
            items: [],
            selectedOptions: {},
            options: {
                columns: {
                    'Attributes': {
                        field: '',
                        cellRendererFramework: AttributeOptionRow,
                        cellRendererParams: () => {
                            return {
                                preSelectedOptions: () => {
                                    return this.selectedOptions
                                },
                                getValue: () => {
                                    return this.selectedOptions
                                },
                                setOptions: (key, values) => {
                                    if (Array.isArray(this.selectedOptions)) {
                                        this.selectedOptions = {}
                                    }
                                    this.$set(this.selectedOptions, key, values)
                                },
                            }
                        },
                    },
                },
                paginationPageSize: 10,
                cacheBlockSize: 10,
            },
        }
    },
    computed: {
        ...mapState('products', ['shopAgreements', 'agreement']),
        action () {
            if (!this.agreement) {
                return null
            }
            return CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.SET_ATTRIBUTE_OPTIONS_MAPPING
                .replace('{agreementId}', this.agreement.agreementId)
                .replace('{shopHash}', this.agreement.shopHash)
        },
    },
    watch: {
        agreement: {
            handler () {
                if (!this.agreement) {
                    return
                }

                const url = CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.LIST_ATTRIBUTE_OPTIONS_DEFINITION_MAPPINGS.replace('{agreementId}', this.agreement.agreementId).replace('{shopHash}', this.agreement.shopHash)
                this.addJob(url)
                this.$api.get(url).then(({ data }) => {
                    this.items = data.data
                }).catch(err => {
                    this.$emit('error', err.response)
                }).finally(() => {
                    this.finishJob(url)
                })
            },
            immediate: true,
        },
    },
    methods: {
        getProductDefinitions (agreement) {
            this.url = CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.GET_ATTRIBUTES_BY_SHOP.replace('{shopHash}', agreement.shopHash)
        },
        editProductLabel (item) {
            this.$set(this, 'activeItem', item.label)
            this.$set(this, 'selectedOptions', item.options)
        },
        appendLabel (data) {
            this.items.push(data)
        },
        updateLabel (label) {
            const payload = {
                options: this.selectedOptions,
                label: label.label,
            }

            this.addJob(this.action)

            this.$api.post(this.action, payload).then(({ data }) => {
                const index = this.items.findIndex(item => item.label === data.data.label)
                this.items[index] = data.data
                this.$root.$emit('notification:global', {
                    message: 'Attribute definition label was updated',
                })
                this.$refs.listing.activeLabel = null
                this.$refs.listing.isAction = false
                this.selectedOptions = {}
            }).catch(err => {
                console.log(err.response)
            }).finally(() => {
                this.finishJob(this.action)
            })
        },
        deleteLabel (label) {
            const url = CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.REMOVE_ATTRIBUTE_OPTIONS_MAPPING
                .replace('{agreementId}', this.agreement.agreementId)

            const payload = {
                label: label.label,
            }

            this.addJob(url)

            this.$api.post(url, payload).then(({ data }) => {
                const index = this.items.findIndex(item => item.label === data.data.label)
                this.items.splice(index, 1)
                this.$root.$emit('notification:global', {
                    message: 'Attribute definition label was deleted',
                })
                this.$refs.listing.activeLabel = null
                this.$refs.listing.isAction = false
                this.selectedOptions = {}
            }).catch(err => {
                console.log(err.response)
            }).finally(() => {
                this.finishJob(url)
            })
        },
    },
}
</script>
